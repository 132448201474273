.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 1vmin);
  color: white;
}

.Inputs {
  width: 100%;
  display: flex;
  text-align: right;
  flex-direction: column;
  align-items: right;
  justify-content: right;
}

.Input {
  flex-direction: column;
  align-items: right;
  justify-content: right;
}

.Left-side {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border-right: solid #6f73797a 4px;
}

.Left-side-top {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
}

.Right-side {
  width: 50%;
  padding: 15px;
  font-size: calc(5px + 1vmin);

}

.Wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.Result-p {
  margin: 2px;
  padding: 0px;
  text-align: left;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
